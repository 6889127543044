// import { adze, createShed } from 'adze';
// createShed({
//     globalCfg: {
//         logLevel: 9, // TODO: environment based config
//         useEmoji: true
//     }
// });
// const logger = adze;

export default function () {
    return {
        info: console.log,
        debug: console.log,
        alert: console.log,
        error: console.log,
        verbose: console.log,
    }
};