import { getDefaultUser } from "./profile";
import logger from "./logger";

export async function enforceLogin() {
    var user = await getDefaultUser();
    logger().verbose(user);
    if (!user.uid) {
        logger().info("empty user");
        throw (new Error("not logged in"))
    }
    return user;
};