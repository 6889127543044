import { merge } from 'lodash';
import { ofetch } from 'ofetch';
import logger from './logger.ts';

let defaultUid = localStorage.getItem('uid');

const setDefaultUser = function (uid: string) {
    console.log("setting default user " + uid);
    localStorage.setItem('uid', uid);
}

type cacheType = {
    [key: string]: string
}

const getUserProfile = (function gupInit() {
    var cache: cacheType = {};
    return async function (uid: string | Promise<string>, force = false) {
        let localuid = await uid;

        if (!uid) {
            logger().info("undefined uid");
            return {};
        }

        if (!force && cache[localuid]) {
            return cache[localuid];
        }

        logger().verbose("grabbing " + localuid)
        logger().verbose("defaultuid " + defaultUid);

        if (defaultUid === null) {
            throw new Error("no default uid");
        }

        const profile = await ofetch("https://api.chefgemini.com/profile", {
            method: "GET",
            headers: {
                "Authorization": defaultUid
            }
        })
        cache[localuid] = profile;
        return profile;
    }
})();

const getDefaultUser = async function () {
    const uid = localStorage.getItem('uid');
    if (!uid) {
        return {};
    }
    return await getUserProfile(uid);
}

const saveProfile = async function (newProfileParams: Object): Promise<Object> {
    const uid = localStorage.getItem('uid');
    if (!uid) {
        return {};
    }
    var existing = await getDefaultUser();
    var updated = merge({}, existing, newProfileParams);
    const updatedProfile = await ofetch("https://api.chefgemini.com/profile", {
        method: 'POST',
        headers: {
            "Authorization": uid
        },
        body: updated
    })
    return getDefaultUser();
}

export { setDefaultUser, getUserProfile, getDefaultUser, saveProfile };